import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Gallery from './Gallery';
import Footer from './Footer';
import { Bees } from './components/BeeAnimation';

interface AllStats {
  zgIstok: StatsData;
  zgBotanicki: StatsData;
  vz: StatsData;
}
function App() {
  let [stats, setStats] = useState<AllStats | null>(null);

  useEffect(() => {
    fetch('https://www.go2digital.hr/api/kv/gigabeetno').then(async (resp) => {
      let data = await resp.json();
      setStats(data['value']);
    });
  }, []);

  let beeBox = { minX: 0, maxX: 600, minY: 0, maxY: 400 };

  return (
    <div>
      <header>
        <img src={require('./images/logo.png')} width={320} alt="" />
        <video src={require('./images/bees.mp4')} muted autoPlay loop />
      </header>
      <main>
        <div className="section-1-about">
          <div>
            <div className="subtitle">O projektu</div>
            <h1 className="title">GigaBEEtan projekt za GigaBEEtne pčele</h1>
            <p className="text">
              Mali koraci vode do velikih stvari. Upravo zato činimo pravu stvar
              i štitimo pčele jer one su zaista – gigaBEEtne.
            </p>
            <a className="btn" href="/o-projektu">
              Saznaj više
            </a>
          </div>
          <div>
            <video
              src={require('./images/section-1-video.mp4')}
              muted
              autoPlay
              loop
            />
          </div>
        </div>


        <div className="section-7">
          <div className="section-7-description">
            <div>
              <div className="subtitle">Nagradni natječaj</div>
              <h1 className="title">Kad odrastem želim BEEti...</h1>
              <p className="text">
              Krećemo s novim nagradnim natječajem za vrtiće i osnovne škole. Klikni i saznaj više informacija!
              </p>
              <a className="pravila" target="_blank" href="/pravila2024.pdf">
                Pravila nagradnog natječaja
              </a>
            </div>
            <div className="section-7-links">
              <a className="btn" href="/kad-odrastem-zelim-beeti">
              Saznaj više
              </a>
              <a className="simple-link" href="/svi-radovi">
                Pogledaj završene natječaje
              </a>
            </div>
          </div>
          <div>
            <img
              src={require('./images/zelimbiti.jpg')}
              alt="GigaBeetni bonton pobjednici"
            />
          </div>
        </div>

        {stats && (
          <div className="section-2-analitika">
            <div>
              <div className="subtitle">Analitika</div>
              <h1 className="title2">Trenutno stanje kvalitete zraka</h1>
              <p className="text2">
                <img src={require('./images/scale.png')} alt="" />
              </p>
            </div>
            <div>
              <h2>
                <b>Zagreb Istok</b> / Hala
              </h2>
              <Stats data={stats.zgIstok} />

              <h2 style={{ marginTop: '40px' }}>
                <b>Zagreb Centar</b> / Botanički vrt PMF-a
              </h2>
              <Stats data={stats.zgBotanicki} />

              <h2 style={{ marginTop: '40px' }}>
                <b>Varaždin</b> / Gospodarska ulica
              </h2>
              <Stats data={stats.vz} />

              <ReactTooltip place="top" effect="solid" className="tooltip" />
            </div>
          </div>
        )}

        <div className="section-3-smart">
          <div>
            <h1>Brinemo se o pčelama kako bi se one brinule o nama</h1>
            <p>
              Budući da rad i život pčela ovisi o kvaliteti zraka, postavili smo
              najsuvremenije senzore koji u realnom vremenu prate i izvještavaju
              o trenutnoj kvaliteti zraka.
            </p>
            <p>
              To je samo jedno od Pametnih zelenih rješenja kojima A1 Hrvatska
              nastoji povećati kvalitetu života i efikasnost korištenja resursa
              kao i njihovu održivost.
            </p>
            <a
              className="btn"
              style={{ maxWidth: '245px' }}
              href="https://www.pametnarjesenja.hr/usluge/kategorija/pametni-gradovi"
              target="_blank"
              rel="noreferrer"
            >
              Saznaj više
            </a>
          </div>

          <div>
            <img src={require('./images/smart.jpg')} alt="" />
          </div>
        </div>

        <div className="section-3-quote">
          <div className="img">
            <img
              src={require('./images/prof.jpg')}
              width="400"
              height="400"
              alt="Prof. dr. sc. Nikola Kezić"
              loading="lazy"
            />
          </div>
          <div className="quote">
            <p>
              Jedna solitarna pčela oprašuje jednako efikasno kao i 120
              medonosnih pčela, zbog čega je njihov opstanak ključan za zelene
              gradove. Stoga im je potrebno na druge načine osigurati siguran
              dom, kao što su hoteli za pčele.
            </p>
            <p>Prof. dr. sc. Nikola Kezić</p>
          </div>
          <div className="bees" />
        </div>

        <div className="section-4-edu">
          <div>
            <div className="subtitle">Edukacija</div>
            <h1 className="title3">Upoznaj se sa svijetom pčela</h1>
          </div>
          <p className="text2">
            <a
              className="btn download"
              download
              href="/Infografika_o_pcelama.pdf"
            >
              Infografika o pčelama
            </a>

            <a
              className="btn download"
              download
              href="/Kako_izraditi_hotel_za_pcele.pdf"
            >
              Upute za izradu hotela za pčele
            </a>
          </p>
        </div>

        <div className="section-5-videos">
          <a href="/gigabeetna-radionica">
            <img src={require('./images/edu/t17.thumb.jpg')} alt="" />
          </a>

          <a href="/o-pcelama">
            <img src={require('./images/edu/t1.thumb.jpg')} alt="" />
          </a>

          <a href="/zivot-u-kosnici">
            <img src={require('./images/edu/t2.thumb.jpg')} alt="" />
          </a>

          <a href="/proizvodnja-meda">
            <img src={require('./images/edu/t3.thumb.jpg')} alt="" />
          </a>

          <a href="/radim-li-kao-pcela">
            <img src={require('./images/edu/t4.thumb.jpg')} alt="" />
          </a>

          <a href="/karijera-jedne-pcele">
            <img src={require('./images/edu/t5.thumb.jpg')} alt="" />
          </a>

          <a href="/gdje-pcela-nauci-raditi-med">
            <img src={require('./images/edu/t6.thumb.jpg')} alt="" />
          </a>

          <a href="/muskarci-trutovi-zene-kraljice">
            <img src={require('./images/edu/t7.thumb.jpg')} alt="" />
          </a>

          <a href="/sto-kada-umre-kraljica-pcela">
            <img src={require('./images/edu/t8.thumb.jpg')} alt="" />
          </a>

          <a href="/pcele-se-dresiraju">
            <img src={require('./images/edu/t9.thumb.jpg')} alt="" />
          </a>

          <a href="/biljke-za-pcele">
            <img src={require('./images/edu/t10.thumb.jpg')} alt="" />
          </a>

          <a href="/hotel-za-pcele">
            <img src={require('./images/edu/t11.thumb.jpg')} alt="" />
          </a>

          <a href="/med-probiotik">
            <img src={require('./images/edu/t12.thumb.jpg')} alt="" />
          </a>

          <a href="/smanjenje-stope-oprasivanja">
            <img src={require('./images/edu/t13.thumb.jpg')} alt="" />
          </a>

          <a href="/pet-mocnih-recepata">
            <img src={require('./images/edu/t14.thumb.jpg')} alt="" />
          </a>

          <a href="/kako-zuje-pcele-u-hrvatskoj">
            <img src={require('./images/edu/t15.thumb.jpg')} alt="" />
          </a>

          <a href="/najkorisnije-cudoviste">
            <img src={require('./images/edu/t16.thumb.jpg')} alt="" />
          </a>

          <a href="#" style={{ visibility: 'hidden' }} />
        </div>

        {/* <div className="section-7">
          <div className="section-7-description">
            <div>
              <div className="subtitle">Nagradni natječaj</div>
              <h1 className="title">GigaBEEtni Bonton</h1>
              <p className="text">
                Nagradni natječaj dječjih vrtića i osnovnih škola na temu
                “GigaBEEtni Bonton” završio je 25.10.2023. U natječaju je
                sudjelovalo 48 dječjih vrtića i osnovnih škola iz cijele
                Hrvatske.
              </p>
              <a className="pravila" target="_blank" href="/pravila2023.pdf">
                Pravila nagradnog natječaja
              </a>
            </div>
            <div className="section-7-links">
              <a className="btn" href="/gigabeetni-bonton">
                Pogledajte sve radove
              </a>
              <a className="simple-link" href="/svi-radovi">
                Pogledaj završene natječaje
              </a>
            </div>
          </div>
          <div>
            <img
              src={require('./images/gigaBEEtniBonton_Pobjednici.jpg')}
              alt="GigaBeetni bonton pobjednici"
            />
          </div>
        </div>

        <Gallery /> */}
      </main>
      <Footer />
    </div>
  );
}

interface StatsData {
  values: Array<{ name: string; value: number }>;
  indexes: Array<{
    name: string;
    level: 'VERY_LOW' | 'LOW' | 'MEDIUM' | 'HIGH' | 'VERY_HIGH';
    value: number;
  }>;
}

function getValue(data: StatsData, name: string) {
  let v = data.values.find((v) => v.name === name);
  return v == null ? v : Math.round(v.value);
}

function getCAQI(data: StatsData) {
  let v = data.indexes.find((v) => v.name === 'AIRLY_CAQI');
  return v == null ? v : { level: v.level, value: Math.round(v.value) };
}

let LEVEL_COLOR = {
  VERY_LOW: '#79BC6A',
  LOW: '#B9CE45',
  MEDIUM: '#EDC100',
  HIGH: '#F69208',
  VERY_HIGH: '#960018',
};

function Stats(props: { data: StatsData }) {
  let { data } = props;
  let caqi = getCAQI(data);

  return (
    <div className="stats">
      <div
        className="caqi"
        style={{ backgroundColor: LEVEL_COLOR[caqi?.level || 'MEDIUM'] }}
      >
        <div>{caqi == null ? '-' : caqi.value}</div>
        <div>CAQI</div>
      </div>

      <div className="dataGroup">
        <div className="data">
          <p
            className="type"
            data-tip="PM10 su onečišćivači zraka promjera 10 mikrometara (tisućiti dio milimetra) ili manje."
          >
            PM10<sup>ⓘ</sup>
          </p>
          <p className="value">
            <b>{getValue(data, 'PM10')}</b>
            <span>µg/m³</span>
          </p>
        </div>

        <div className="data">
          <p
            className="type"
            data-tip="PM2,5 su onečišćivači zraka promjera 2,5 mikrometara (tisućiti dio milimetra) ili manje."
          >
            PM2.5<sup>ⓘ</sup>
          </p>
          <p className="value">
            <b>{getValue(data, 'PM25')}</b>
            <span>µg/m³</span>
          </p>
        </div>

        <div className="data">
          <p
            className="type"
            data-tip="PM1 su onečišćivači zraka promjera 1 mikrometar (tisućiti dio milimetra) ili manje."
          >
            PM1<sup>ⓘ</sup>
          </p>
          <p className="value">
            <b>{getValue(data, 'PM1')}</b>
            <span>µg/m³</span>
          </p>
        </div>

        <div className="data">
          <p className="type">Tlak zraka</p>
          <p className="value">
            <b>{getValue(data, 'PRESSURE')}</b>
            <span>hPa</span>
          </p>
        </div>

        <div className="data">
          <p className="type">Vlaga</p>
          <p className="value">
            <b>{getValue(data, 'HUMIDITY')}</b>
            <span>%</span>
          </p>
        </div>

        <div className="data">
          <p className="type">Temperatura</p>
          <p className="value">
            <b>{getValue(data, 'TEMPERATURE')}</b>
            <span>°C</span>
          </p>
        </div>

        <div className="data">
          <p
            className="type"
            data-tip="NO₂ je spoj koji zagađuje našu atmosferu, a njegov glavni izvor je cestovni promet."
          >
            NO2<sup>ⓘ</sup>
          </p>
          <p className="value">
            <b>{getValue(data, 'NO2')}</b>
            <span>µg/m³</span>
          </p>
        </div>

        <div className="data">
          <p
            className="type"
            data-tip="Ozon (O₃) je otrovan plin, koji nastaje u procesu fotokemijskih reakcija u atmosferi, osobito tijekom proljeća i ljeta.
"
          >
            O3<sup>ⓘ</sup>
          </p>
          <p className="value">
            <b>{getValue(data, 'O3')}</b>
            <span>µg/m³</span>
          </p>
        </div>

        <div className="data">
          <p className="type"></p>
          <p className="value">
            <b></b>
            <span></span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
